import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { MobipaidPage } from '../master/payment-gateway/non-recurring/mobipaid/mobipaid.page';
import { AlertController } from '@ionic/angular';
import { CommonService } from './common.service';
import { CaseService } from './case.service';
import { USAEPayMethodPage } from '../master/payment-gateway/non-recurring/usaepay/usaepay-method/usaepay-method.page';
import { PayRocPage } from '../master/payment-gateway/non-recurring/payroc/payroc.page';
import { StripepayPage } from '../master/payment-gateway/non-recurring/stripepay/stripepay.page';
import { AuthService } from './auth.service';
import { GoCardlessPage } from '../master/payment-gateway/non-recurring/gocardless/gocardless.page';
import { AcquiredPage } from '../master/payment-gateway/non-recurring/acquired/acquired.page';
import { TrxServicesMethodPage } from '../master/payment-gateway/non-recurring/trxservices/trxservices-method/trxservices-method.page';
import { USAEPayAchPage } from '../master/payment-gateway/non-recurring/usaepay/usaepay-ach/usaepay-ach.page';
import { USAepayCardPage } from '../master/payment-gateway/non-recurring/usaepay/usaepay-card/usaepay-card.page';
import { TrxServicesAchPage } from '../master/payment-gateway/non-recurring/trxservices/trxservices-ach/trxservices-ach.page';
import { TrxServicesCardPage } from '../master/payment-gateway/non-recurring/trxservices/trxservices-card/trxservices-card.page';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(
    private alertController: AlertController,
    private commonService: CommonService,
    private caseService: CaseService,
    private authService: AuthService
  ) {}

  paymentList: any = {
    mobipaid: {
      text: 'Mobipaid',
      handler: (caseData) => {
        this.goToMobipaid(caseData);
      },
    },
    usaepay: {
      text: 'USA ePay',
      handler: () => {
        this.goToPaymentAmountPopup();
      },
    },
    govukpay: {
      text: 'GOV.UK Pay',
      handler: (caseData) => {
        this.goToGovukpay(caseData);
      },
    },
    sagepay: {
      text: 'Sage Pay',
      handler: () => {},
    },
    payroc: {
      text: 'Pay Roc',
      handler: () => {
        this.goToPaymentAmountPopup();
      },
    },
    trxServices: {
      text: 'EzPay / TRX Services',
      handler: () => {
        this.goToPaymentAmountPopup();
      },
    },
    stripe: {
      text: 'Stripe Pay',
      handler: () => {
        this.goToPaymentAmountPopup();
      },
    },
    gocardless: {
      text: 'GoCardless Pay',
      handler: () => {
        this.goToPaymentAmountPopup();
      },
    },
    acquired: {
      text: 'Acquired Pay',
      handler: () => {
        this.goToPaymentAmountPopup();
      },
    },
  };
  availablePaymentListArray = [];
  paymentBtnDisabled = false;
  paymentGatewayList = [];
  defaultPaymentPortal = '';
  govuktitle = 'Lateral Payment Portal';

  async goToMobipaid(caseData, fullAmount = false) {
    this.caseService.ionNav.push(MobipaidPage, {
      caseId: caseData.id,
      debtorDetails: this.authService.debtorDetails,
      caseData: caseData,
      totalAmount : this.getCaseOutStanding(caseData),
    });
  }

  onInit() {
    this.checkAvailablePaymentList();
  }
  async presentActionSheet(caseData, fullAmount = false) {
    let title = 'Lateral Payment Portal';
    if (this.availablePaymentListArray.length > 1) {
      const inputs: any = this.availablePaymentListArray.map((gateway) => {
        return {
          name: 'selected_gateway',
          type: 'radio',
          label: gateway.text,
          value: gateway.value,
        };
      });
      const alert = await this.alertController.create({
        header: title,
        message: 'Select Payment Gateway',
        inputs: inputs,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Continue',
            role: 'OK',
            handler: async (value) => {
              await this.handleGatewaySelect(caseData, value, fullAmount);
            },
          },
        ],
      });
      await alert.present();
    } else if (this.defaultPaymentPortal) {
      await this.handleGatewaySelect(
        caseData,
        this.defaultPaymentPortal,
        fullAmount
      );
    } else {
      this.commonService.showToast('No payment gateway configured!', 'danger');
    }
  }

  handleGatewaySelect(caseData, selected_gateway, fullAmount = false) {
    switch (selected_gateway) {
      case 'mobipaid':
        this.goToMobipaid(caseData, fullAmount);
        break;
      case 'usaepay':
      case 'payroc':
      case 'stripe':
      case 'gocardless':
      case 'acquired':
      case 'trxServices':
        this.goToPaymentAmountPopup(selected_gateway, caseData, fullAmount);
        break;
      case 'govukpay':
        this.goToGovukpay(caseData, fullAmount);
        break;
      case 'sagepay':
        break;
      default:
        break;
    }
  }

  checkAvailablePaymentList() {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      this.availablePaymentListArray = [];
      Object.values(payment_type).forEach((value: any, index) => {
        if (index == 0) {
          this.defaultPaymentPortal = value.key;
        }
        if (this.paymentList[value.key]) {
          this.paymentList[value.key].text =
            value.label || this.paymentList[value.key].text;
          this.paymentList[value.key].value = value.key;
          this.availablePaymentListArray.push(this.paymentList[value.key]);
        }
      });
    }
    if (this.availablePaymentListArray.length == 0) {
      this.paymentBtnDisabled = true;
    }
  }
  async goToPaymentAmountPopup(type = '', caseData = null, fullAmount = false) {
    const handler = async (res) => {
      //If user accepted the Settlement Offer. Then, It'll set the amount same as the settlement Amount.
      res.amount = (caseData.acceptSettlementOffer) ? caseData.settlementAmount : res.amount;
      if (res.amount <= 0) {
        this.commonService.showToast('Selected amount should be greater than zero!', 'danger');
        return;
      }
      if (type == 'usaepay') {
        await this.goToUSAepay(res.amount, caseData);
      } else if (type == 'payroc') {
        await this.goToPayRoc(res.amount, caseData);
      } else if (type == 'stripe') {
        await this.goToCurrencyCode(res.amount, caseData);
      } else if (type == 'gocardless') {
        await this.goToGoCardless(res.amount, caseData);
      } else if (type == 'acquired') {
        await this.acquired(res.amount, caseData);
      } else if (type == 'trxServices') {
        await this.goToTrxServices(res.amount, caseData);
      }
    };
    if (fullAmount) {
      const amount = this.getCaseOutStanding(caseData);
      if (amount <= 0) {
        this.commonService.showToast('Selected amount should be greater than zero!', 'danger');
        return;
      }
      await handler({ amount: amount });
      return;
    }
    const alert = await this.alertController.create({
      message: 'Enter the amount',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Continue',
          role: 'OK',
          handler: async (res) => {
            await handler(res);
          },
        },
      ],
      inputs: [
        {
          type: 'number',
          name: 'amount',
          placeholder: 'Amount...',
          value: this.getCaseOutStanding(caseData),
          min: 1,
        },
      ],
    });

    await alert.present();
  }

  async goToUSAepay(amount, caseData) {
    this.caseService
      .makeUSAePaymentRequest(caseData.id, { amount })
      .subscribe(async (res: any) => {
        if (res.result) {
          let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
          const paymentMethod = portalSettings?.payment_type['usaepay'];

          if(paymentMethod.options.length < 2) {
            if(paymentMethod.options.includes('ach')) {
              this.caseService.ionNav.push(USAEPayAchPage, {
                amount: amount,
                caseId: caseData.id,
                totalOutstandingWithSymbol: this.getCaseOutStanding(caseData),
                debtorDetails: this.authService.debtorDetails,
                responseData: res.data,
                caseDetails: caseData,
              });
            } else if(paymentMethod.options.includes('card')) {
              this.caseService.ionNav.push(USAepayCardPage, {
                amount: amount,
                caseId: caseData.id,
                totalOutstandingWithSymbol: this.getCaseOutStanding(caseData),
                debtorDetails: this.authService.debtorDetails,
                responseData: res.data,
                caseDetails: caseData,
              });
            }
          } else {
            this.caseService.ionNav.push(USAEPayMethodPage, {
              amount: amount,
              caseId: caseData.id,
              totalOutstandingWithSymbol: this.getCaseOutStanding(caseData),
              debtorDetails: this.authService.debtorDetails,
              responseData: res.data,
              caseDetails: caseData,
            });
          }
        } else {
          this.commonService.showToast(res['message'], 'danger');
          return false;
        }
      });
  }
  getCaseOutStanding(caseData) {
    return caseData?.d_outstanding.replace(/[^\d.]/g, '');
  }

  async goToPayRoc(amount, caseData) {
    this.caseService
      .makePayrocPaymentRequest(caseData.id, { amount })
      .subscribe(async (res: any) => {
        if (res.result) {
          this.caseService.ionNav.push(PayRocPage, {
            caseId: caseData.id,
            totalOutstandingWithSymbol: this.getCaseOutStanding(caseData),
            responseData: res.data,
            caseDetails: caseData,
          });
        }
      });
  }

  async goToGoCardless(amount, caseData) {
    this.caseService
      .makeGocardlessPaymentRequest(caseData.id, { amount })
      .subscribe(async (res: any) => {
        if (res.result) {
          this.caseService.ionNav.push(GoCardlessPage, {
            caseId: caseData.id,
            totalOutstandingWithSymbol: this.getCaseOutStanding(caseData),
            responseData: res.data,
            caseDetails: caseData,
          });
        }
      });
  }

  async acquired(amount, caseData) {
    this.caseService
      .makeAcquiredPaymentRequest(caseData.id, { amount })
      .subscribe(async (res: any) => {
        if (res.result) {
          this.caseService.ionNav.push(AcquiredPage, {
            caseId: caseData.id,
            totalOutstandingWithSymbol: this.getCaseOutStanding(caseData),
            responseData: res.data,
            caseDetails: caseData,
          });
        }
      });
  }

  async goToTrxServices(amount, caseData) {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    let message = '';
    let restrictPayment = false;
    if (portalSettings.payment_amount_limit_exceed > 0  && parseFloat(amount) > parseFloat(portalSettings.payment_amount_limit_exceed)) {
      message = portalSettings.payment_amount_limit_exceed_message;
      restrictPayment = true;
    } else if ((portalSettings.payment_amount_limit_min > 0  && parseFloat(amount) < parseFloat(portalSettings.payment_amount_limit_min))) {
      message = portalSettings.payment_amount_limit_min_message
      restrictPayment = true;
    }
    if (restrictPayment) {
      const alert = await this.alertController.create({
        header: 'Payment Limit Out Of Range',
        message: message,
        buttons: ['OK'],
      });
      await alert.present();
      return;
    }
    this.caseService
      .makeTrxServicesPaymentRequest(caseData.id, { amount })
      .subscribe(async (res: any) => {
        if (res.result) {
          let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
          const paymentMethod = portalSettings?.payment_type['trxServices'];
          if(paymentMethod.options && paymentMethod.options.length < 2) {
            if(paymentMethod.options.includes('ach')) {
              this.caseService.ionNav.push(TrxServicesAchPage, {
                amount: amount,
                caseId: caseData.id,
                totalOutstandingWithSymbol: this.getCaseOutStanding(caseData),
                debtorDetails: this.authService.debtorDetails,
                responseData: res.data,
                caseDetails: caseData,
              });
            } else if(paymentMethod.options.includes('card')) {
              this.caseService.ionNav.push(TrxServicesCardPage, {
                amount: amount,
                caseId: caseData.id,
                totalOutstandingWithSymbol: this.getCaseOutStanding(caseData),
                debtorDetails: this.authService.debtorDetails,
                responseData: res.data,
                caseDetails: caseData,
              });
            }
          } else {
            this.caseService.ionNav.push(TrxServicesMethodPage, {
              caseId: caseData.id,
              totalOutstandingWithSymbol: this.getCaseOutStanding(caseData),
              responseData: res.data,
              caseDetails: caseData,
              debtorDetails: this.authService.debtorDetails, // added debtor details in trxservice method page
            });
          }
        }
      });
  }
  async goToCurrencyCode(amount, caseData) {
    // let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    // let title = 'Lateral Payment Portal';
    // if (portalSettings?.payment_type) {
    //   let payment_type = portalSettings?.payment_type
    //     ? portalSettings?.payment_type
    //     : {};
    //   if (payment_type?.usaepay && payment_type?.usaepay?.label) {
    //     title = payment_type.usaepay.label;
    //   }
    // }
    // await this.alertController.dismiss();
    // const alert = await this.alertController.create({
    //   header: title,
    //   message: 'Select currency code',
    //   inputs: [
    //     {
    //       name: 'currency_code',
    //       type: 'radio',
    //       label: 'US',
    //       value: 'usd',
    //       checked: true,
    //     },
    //     {
    //       name: 'currency_code',
    //       type: 'radio',
    //       label: 'EUR',
    //       value: 'eur',
    //     },
    //     {
    //       name: 'currency_code',
    //       type: 'radio',
    //       label: 'INR',
    //       value: 'inr',
    //     },
    //     {
    //       name: 'currency_code',
    //       type: 'radio',
    //       label: 'GBP',
    //       value: 'gbp',
    //     },
    //   ],
    //   buttons: [
    //     {
    //       text: 'Cancel',
    //       role: 'cancel',
    //       handler: () => {},
    //     },
    //     {
    //       text: 'Continue',
    //       role: 'OK',
    //       handler: async (res) => {
    //         await this.goToStripePay(res, amount, caseData);
    //       },
    //     },
    //   ],
    // });
    // await alert.present();
    await this.goToStripePay('gbp', amount, caseData);
  }
  async goToStripePay(currency_code, amount, caseData) {
    this.caseService.ionNav.push(StripepayPage, {
      caseId: caseData.id,
      amount: amount,
      currency_code: currency_code,
      totalOutstandingWithSymbol: this.getCaseOutStanding(caseData),
      debtorDetails: this.authService.debtorDetails,
      caseDetails: caseData,
    });
  }
  async goToGovukpay(caseData, fullAmount = false) {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.govukpay && payment_type?.govukpay?.label) {
        this.govuktitle = payment_type.govukpay.label;
      }
    }
    const alert = await this.alertController.create({
      header: this.govuktitle,
      message: 'Enter the amount',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Continue',
          role: 'OK',
          handler: async (res) => {
            await this.saveGovpay(res.amount, caseData);
          },
        },
      ],
      inputs: [
        {
          type: 'number',
          name: 'amount',
          placeholder: 'Amount...',
          value: this.getCaseOutStanding(caseData),
          min: 1,
        },
      ],
    });

    await alert.present();
  }
  async saveGovpay(amount, caseData) {
    if (amount <= 0) {
      this.commonService.showToast('Selected amount should be greater than zero!', 'danger');
      return;
    }
    let obj = {
      amount: Math.floor(amount * 100),
      reference: caseData?.ref ? caseData?.ref : '',
      description: caseData?.offense ? caseData?.offense : '',
      email: this.authService.debtorDetails?.debtor_email
        ? this.authService.debtorDetails?.debtor_email
        : '',
      cardholder_name: this.authService.debtorDetails?.debtor_name
        ? this.authService.debtorDetails?.debtor_name
        : '',
      line1: this.authService.debtorDetails?.addresses[0]?.address_ln1
        ? this.authService.debtorDetails?.addresses[0]?.address_ln1
        : '',
      line2: this.authService.debtorDetails?.addresses[0]?.address_ln2
        ? this.authService.debtorDetails?.addresses[0]?.address_ln2
        : '',
      city: this.authService.debtorDetails?.addresses[0]?.address_town
        ? this.authService.debtorDetails?.addresses[0]?.address_town
        : '',
      postcode: this.authService.debtorDetails?.addresses[0]?.address_postcode
        ? this.authService.debtorDetails?.addresses[0]?.address_postcode
        : '',
      CaseID: caseData.id,
      SummonsNo: caseData?.ref ? caseData?.ref : caseData.id,
      AccountNo: caseData?.cl_ref ? caseData?.cl_ref : caseData.id,
      return_url: window.location.href,
    };
    this.commonService.showLoader();
    this.caseService.getGovUKPayLink(obj).subscribe((res: any) => {
      console.log(res);
      if (res.result) {
        if (res.data?.payment_url) {
          var win = window.open(res.data.payment_url, '_self');
          if (win) {
            win.focus();
          }
        }
      } else {
        this.commonService.showToast(res['message'], 'danger');
      }
      this.commonService.dismissLoader();
    });
  }
}
