import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MasterFooterModule } from '../master-footer/master-footer.module';
import { MasterHeaderModule } from '../master-header/master-header.module';
import { ViewPaymentPlanPage } from './view-payment-plan.page';
import { ViewPaymentPlanPageRoutingModule } from './view-payment-plan-routing.module';
import { EditPaymentPlanComponent } from './edit-payment-plan/edit-payment-plan.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ViewPaymentPlanPageRoutingModule,
    MasterFooterModule,
    MasterHeaderModule,
  ],
  declarations: [ViewPaymentPlanPage, EditPaymentPlanComponent],
})
export class ViewPaymentPlanPageModule {}
