import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-edit-payment-plan',
  templateUrl: './edit-payment-plan.component.html',
  styleUrls: ['./edit-payment-plan.component.scss'],
})
export class EditPaymentPlanComponent implements OnInit {
  @Input() scheduledPayment: any;
  @Input() caseId: any;
  paymentForm: FormGroup;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private commonService: CommonService,
  ) {}

  ngOnInit() {
    this.paymentForm = this.formBuilder.group({
      amount: [this.scheduledPayment.amount, Validators.required],
      amount_paid: [this.scheduledPayment.arr_balance, Validators.required],
      date: [this.scheduledPayment.date, Validators.required],
    });
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async onSubmit() {
    if (this.paymentForm.valid) {
      try {
        const date = this.paymentForm.value.date;
        const formattedDate = date.split('T')[0];
        const formData = {
          amount: this.paymentForm.value.amount,
          date: formattedDate,
          status: this.scheduledPayment.status,
        };
        const res: any = await this.caseService
          .updatePaymentPlan(this.caseId, this.scheduledPayment.id, formData)
          .toPromise();

        if (res.data.success) {
          this.commonService.showToast(res.data.message, 'success');

          const caseData: any = await this.caseService.setArrangementDetails(
            this.caseId
          );

          this.caseService.updateCaseDetails(caseData);
          this.modalController.dismiss({ success: res.data.success });
        } else {
          this.commonService.showToast(res.data.message, 'danger');
          this.modalController.dismiss({ success: res.data.success });
        }
      } catch (error) {
        console.error('Error updating payment:', error);
      }
    }
  }
}
