import { Component, Input, OnInit } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { PaymentDatePage } from '../payment-date/payment-date.page';
import { CustomAmountPage } from '../custom-amount/custom-amount.page';

@Component({
  selector: 'app-payment-frequency',
  templateUrl: './payment-frequency.page.html',
  styleUrls: ['./payment-frequency.page.scss'],
})
export class PaymentFrequencyPage implements OnInit {
  @Input() caseData;
  @Input() planType='plan';
  isCustom=false;
  caseList = [];
  total = 0;
  paymentFrequency = [
    {
      title: 'Weekly',
      type: 'week',
      showOption: false,
      id: '3',
    },
    {
      title: 'Every Other Week',
      type: 'fortnight',
      showOption: false,
      id: '6',
    },
    {
      title: 'Monthly',
      type: 'month',
      showOption: false,
      id: '4',
    },
  ];
  paymentFrequencyLabel = {
    3: 'week',
    4: 'month',
    6: 'biweek',
  }
  options = {
    weekly: [],
  };
  availablePaymentPlanOptions = [];
  paymentPlanOptions = { week: [], month: [], fortnight: [] };

  constructor(private caseService: CaseService, private ionNav: IonNav) {}

  ngOnInit() {
    this.getPaymentPlanOptions();
    if(this.planType=='group_plan'){
      this.caseData.forEach(c => {
        this.caseList.push(c.id);
      });
    }
  }
  toggleOptions(type, value) {
    this.paymentFrequency = this.paymentFrequency.map((op) => ({
      ...op,
      showOption: type === op.type ? value : false,
    }));
  }

  getOutstandingWithoutSymbol(caseData) {
    const amount = caseData?.d_outstanding.replace(
      caseData?.currency_symbol,
      ''
    );
    return parseFloat(amount.replace(',', ''));
  }

  getPaymentPlanOptions() {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    const options = {
      3: 'week',
      4: 'month',
      6: 'fortnight',
    };
    let currentPlan;
    let adjustFinalPaymentMinimum = false;
    let paymentAmountLimitMin = 0;
    this.availablePaymentPlanOptions = [];
    this.paymentPlanOptions = { week: [], month: [], fortnight: [] };
    if (portalSettings?.adjust_final_payment_minimum) {
      adjustFinalPaymentMinimum = portalSettings.adjust_final_payment_minimum;
    }
    if (portalSettings?.payment_amount_limit_min && portalSettings.payment_amount_limit_min) {
      paymentAmountLimitMin = parseFloat(portalSettings.payment_amount_limit_min);
    }
    if (portalSettings?.arrangement && portalSettings.arrangement[this.planType]) {
      let plans = portalSettings?.arrangement[this.planType]
        ? portalSettings?.arrangement[this.planType]
        : [];
        let groupTotal=0;
        let currency;
      if(this.planType=='group_plan'){
        this.caseData.forEach(data => {
          groupTotal+=this.getOutstandingWithoutSymbol(data);
          currency=data.currency_symbol
        });
         this.total=groupTotal;
      }else{
        this.total = this.getOutstandingWithoutSymbol(this.caseData);
        currency=this.caseData.currency_symbol;
      }
      currentPlan = plans.find((plan) => {
        if (plan.type == 'custom') {
          if (plan.range == 'SMALLER_EQUALS' && this.total <= plan.max) {
            return plan;
          } else if (this.total >= plan.min && plan.range == 'BIGGER_EQUALS') {
            return plan;
          } else if (this.total >= plan.min && this.total <= plan.max) {
            return plan;
          }
        }
      });
      if (!currentPlan && plans.find((plan) => plan.type == 'default')) {
        currentPlan = plans.find((plan) => plan.type == 'default');
      }
      // const UsaePay_processing_fee_amount =
      //   this.caseData.UsaePay_processing_fee_amount;
      if(!(currentPlan?.option && currentPlan.option.length)){
        currentPlan.option = [
          {
            "freq": "3",
            "limit": "8"
          },
          {
            "freq": "3",
            "limit": "12"
          },
          {
            "freq": "4",
            "limit": "2"
          },
          {
            "freq": "4",
            "limit": "3"
          },
          {
            "freq": "6",
            "limit": "4"
          },
          {
            "freq": "6",
            "limit": "6"
          }
        ];
      }
      if (currentPlan?.option && currentPlan.option.length) {
        currentPlan.option.forEach((arr) => {
          let amount = parseFloat(
            (
             this.total / arr.limit
            ).toFixed(2)
          );
          if (adjustFinalPaymentMinimum  && paymentAmountLimitMin && amount < paymentAmountLimitMin) {
            return;
          }
          let balance = this.total - amount * arr.limit;
          balance = Math.round(balance * 100) / 100;
          if (balance > 0) {
            amount = Math.round((amount + balance) * 100) / 100;
          }
          let last_emi = amount;
          let emi_balance = (amount * (parseInt(arr.limit)-1)) - this.total;
          if (emi_balance > 0) {
            last_emi = amount - emi_balance;
          } else {
            last_emi = amount + emi_balance;
          }
          if (adjustFinalPaymentMinimum && paymentAmountLimitMin && last_emi < paymentAmountLimitMin) {
            last_emi += amount;
          }
          let scheduledAmount = parseFloat(amount.toString()).toFixed(2);
          let lastScheduledAmount = parseFloat(last_emi.toString()).toFixed(2);
          // amount + parseFloat(UsaePay_processing_fee_amount).toFixed(2);
          let text =
            arr.limit +
            ' payments of <strong class="font-weight-bold">' +
            currency +
            scheduledAmount + '</strong>'+
            ' ' +
            this.paymentFrequencyLabel[arr.freq] +
            'ly' + ' (Final installment: ' + lastScheduledAmount + ')';
          if (this.paymentPlanOptions[options[arr.freq]]) {
            this.paymentPlanOptions[options[arr.freq]].push({
              text,
              handler: (ar) => {
                this.mapToRequiredPaymentPlanOption(
                  amount,
                  arr.freq,
                  1,
                  text,
                  options[arr.freq],
                  scheduledAmount,
                  arr.limit,
                  this.total,
                  this.caseList
                );
              },
            });
          } else {
            this.paymentPlanOptions[options[arr.freq]] = [
              {
                text,
                handler: (ar) => {
                  this.mapToRequiredPaymentPlanOption(
                    amount,
                    arr.freq,
                    1,
                    text,
                    options[arr.freq],
                    scheduledAmount,
                    arr.limit,
                    this.total,
                    this.caseList
                  );
                },
              },
            ];
          }
          this.availablePaymentPlanOptions.push({
            text,
            handler: (ar) => {
              this.mapToRequiredPaymentPlanOption(
                amount,
                arr.freq,
                1,
                text,
                options[arr.freq],
                scheduledAmount,
                arr.limit,
                this.total,
                this.caseList
              );
            },
          });
        });
      }
      if (
        portalSettings.arrangement?.customized_option &&
        portalSettings.arrangement?.customized_option == 'yes'
      ) {
        this.isCustom=true;
        this.availablePaymentPlanOptions.push({
          text: 'Proceed with custom option',
          handler: (ar) => {
            this.mapToRequiredPaymentPlanOption();
          },
        });
      }
    }
  }

  async customPayment() {
    this.mapToRequiredPaymentPlanOption(
      0,
      0,
      1,
      null,
      'custom',
    );
    };
  async mapToRequiredPaymentPlanOption(
    amount = 0,
    freq = 0,
    customPlan = 0,
    text = null,
    paymentFrequencyOption = null,
    scheduledAmount = '',
    limit = 1,
    total=0,
    caseList=[]
  ) {
    this.caseService.setPaymentData({
      paymentFrequencyOption,
      text,
      amount,
      scheduledAmount,
      freq,
      customPlan,
      limit,
      total,
      caseList,
    });
    if(paymentFrequencyOption==='custom' && this.isCustom){
      this.ionNav.push(CustomAmountPage, {caseData: this.caseData, planType: this.planType, total: this.total, caseList: this.caseList });
    }else{
      this.ionNav.push(PaymentDatePage, { caseData: this.caseData, planType: this.planType, selectedPlanData: text});
    }
  }
}
